<template>
  <div>
    <Wrapper>
      <div class="mb-10 text-left">
        <h2 class="text-xl"><b>Información de la nueva dirección</b></h2>
        <p class="text-gray-500">
          Ingrese la información de la dirección y las entidades asociadas
        </p>
      </div>
      <form @submit.prevent="onSubmit" class="mx-auto max-w-2xl">
        <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
          <BaseInput
            type="text"
            label="Nombre de la dirección"
            placeholder="Ej: Dirección de conductas"
            v-model="form.name"
          />
          <BaseMultiSelect
            title="Asociar entidades"
            placeholder="Seleccione el código"
            :data="optionsCompanies"
            label="name"
            @notify-objects="setMultiSelect($event)"
            class="relative"
          />
        </div>
        <div class="w-full grid grid-cols-1 lg:grid-cols-1 gap-2">
          <BaseTextarea
            ref="describe"
            id="describe"
            label="Descripción de la dirección"
            placeholder="Descripción"
            v-model="form.description"
            maxlength="1000"
          />
        </div>
        <div v-if="feedback">
          <p class="my-2 text-xs text-center text-red-500">{{ feedback }}</p>
        </div>
        <div
          class="w-full lg:max-w-sm mt-8 ml-auto flex flex-col lg:flex-row lg:justify-end gap-5"
        >
          <a-button
            type="danger"
            @click="$router.go(-1)"
            ghost
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">CANCELAR</span>
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">GUARDAR</span>
          </a-button>
        </div>
      </form>
    </Wrapper>
  </div>
</template>

<script>
import Wrapper from "../../UI/Wrapper";
export default {
  components: { Wrapper },
  data() {
    return {
      value: 2,
      feedback: null,
      loading: false,
      optionsCompanies: [],
      form: {
        name: "",
        entities: [],
        description: "",
        is_admin: false,
      },
    };
  },
  created() {
    this.loadCompanies();
  },
  methods: {
    async loadCompanies() {
      this.optionsCompanies =
        await this.$store.state?.form.optionsCompanies.filter(
          (company) => company.direction == null
        );
    },
    async onSubmit() {
      if (this.checkForm()) {
        this.loading = true;
        const form = { ...this.form };
        if (form.entities.length > 0) {
          form.is_admin = false;
        } else {
          form.is_admin = true;
        }
        let { error, data } = await this.$api.createDirection(form);
        this.loading = false;

        if (error) this.showToast("error", "Error creando la dirección.");

        if (data) {
          if (data.code === 401) {
            this.showToast("error", data.error);
          } else if (data.code === 402) {
            this.showToast("error", data.error);
          } else if (data.code === 403) {
            this.showToast("error", data.error);
          } else if (data.code === 404) {
            this.showToast("error", data.error);
          } else {
            this.showToast("success", "Dirección creada correctamente.");
            this.$store.dispatch("form/loadListOptions", {
              endpoint: "direction/",
              name: "optionsDirections",
            });
            this.$store.dispatch("form/loadListOptions", {
              endpoint: "company/",
              name: "optionsCompanies",
            });
            this.$router.go(-1);
          }
        }
      }
    },
    checkForm() {
      this.feedback = null;

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = "Es necesario un nombre para la dirección";
        return false;
      }
      return true;
    },
    setMultiSelect(ev) {
      this.form.entities = ev.map((item) => item.id);
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  watch: {
    "$store.state.form.optionsCompanies": async function () {
      this.optionsCompanies =
        await this.$store.state?.form.optionsCompanies.filter(
          (company) => company.direction == null
        );
    },
  },
};
</script>

<style scoped></style>
